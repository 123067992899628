// Element loading
const loadingWrapper = document.getElementById('loadingWrapper');



(function(){
  window.onload = function(){
    // ない場合はなにもしない
    if( !loadingWrapper ) return;
    setTimeout(function() {
      loadingWrapper.classList.add('disabled');
    }, 400);
  }
}());



export const activeLoading = () => {
  loadingWrapper.classList.remove('disabled');
};
