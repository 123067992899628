(function(){

  // タブ処理を行う要素のクラス名
  const tabsClass            = 'js-tab';
  const currentClassName     = 'js-tab-current';
  const currentViewClassName = 'js-tab-current-view';

  // タブ処理を行う要素の取得
  const tabs = document.getElementsByClassName(tabsClass);

  // ない場合はなにもしない
  if( ! tabs ) return;

  // Click event
  for (let i = 0; i < tabs.length; i++){
    tabs[i].addEventListener('click', function(){
      // データ属性を取得する
      const view = this.dataset.view;
      // .current が付与しているかチェック
      const isCurrent = this.classList.contains(currentClassName);
      // クリックしたタブの親要素を取得する
      const tabsParent = this.parentNode.parentNode.parentNode;
      // 他のタブ要素に影響しないようにクリックしたタブ要素だけに絞る
      const tabsChild = tabsParent.getElementsByClassName(tabsClass);
      // コンテンツの要素を取得する
      const allContents = tabsParent.getElementsByClassName('js-contents');

      if (!isCurrent) {
        // remove .current
        for ( let j = 0; j < tabsChild.length; j++ ){
          tabsChild[j].classList.remove(currentClassName);
        };
        this.classList.add(currentClassName);
      };
      // remove .current-view
      for ( let k = 0; k < allContents.length; k++ ){
        allContents[k].classList.remove(currentViewClassName);
      };
      //
      document.getElementById(view).classList.add(currentViewClassName);
    });
  };
}());
