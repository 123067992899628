(function(){
  const burger = document.getElementById('jsBurger');
  // ない場合はなにもしない
  if( ! burger ) return;

  burger.addEventListener('click', function(){
    document.body.classList.toggle('collapse');
  });
}());

(function(){
  const close = document.getElementById('jsClose');
  // ない場合はなにもしない
  if( ! close ) return;

  close.addEventListener('click', function(){
    document.body.classList.toggle('collapse');
  });
}());
