import { getStyle } from './export';



(function(){
  // アコーディオンの親要素のクラス名
  const accordionParentClassName = '.jsAccordion';

  // アコーディオンのクリックイベントを行うクラス名
  const triggerClassName = '.jsAccordionTrigger';

  // アコーディオンを開くためのクラス名
  const activeClassName = 'active';

  // アコーディオンのトリガーをすべて取得する
  const acc = document.querySelectorAll(triggerClassName);

  // クリックイベント
  for ( let i = 0; i < acc.length; i++ ){
    acc[i].addEventListener('click', function(e){
      // アクティブクラスが付いているかチェックする
      const isActive = this.classList.contains(activeClassName);
      // クリックした要素のアコーディオンの親要素を取得する
      const parentAccordion = this.closest(accordionParentClassName);

      // すべてのアコーディオンを閉じる
      /*
      const allAccordions = parentAccordion.querySelectorAll(triggerClassName);
      for ( let j = 0; j < allAccordions.length; j++) {
        // Remove active class from section header
        allAccordions[j].classList.remove(activeClassName);
        // Remove the max-height class from the panel to close it
        const panel = allAccordions[j].nextElementSibling;
        const maxHeightValue = getStyle(panel, 'maxHeight');

        if (maxHeightValue !== '0px') {
          panel.style.maxHeight = null;
        };
      };
      */

      // アクティブクラスの付け替え
      isActive ? this.classList.remove(activeClassName) : this.classList.add(activeClassName);

      // アコーディオンの開閉処理
      const panel = this.nextElementSibling;
      const maxHeightValue = getStyle( panel, 'maxHeight' );
      if (maxHeightValue !== '0px') {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px';
      };
    });
  }
}());
